'use strict';

import sal from 'sal.js';

window.addEventListener('load', function () {
  setTimeout(() => {
    console.log('hiding the first loading element');
    sal();
  }, 400)

});
